import React, { useState, useEffect } from 'react';
import { Send, Users, UserPlus, FileText, Edit, MessageSquare, Globe } from 'lucide-react';
import './AdminDashboard.css';

const KezaAdminDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('english');
  const [nurses, setNurses] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    setMessages([{ text: getRandomGreeting(language), sender: 'bot' }]);
  }, [language]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setLoading(true);

    try {
      // Simulating API call
      const response = await simulateApiCall(inputMessage);
      setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "An error occurred. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setLoading(true);
    try {
      let response;
      switch (action) {
        case 'getNurseList':
          response = await getNurseList();
          setNurses(response);
          setMessages(prevMessages => [...prevMessages, { text: `Nurse List:\n${response.map(n => `${n.name} (ID: ${n.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'getDoctorList':
          response = await getDoctorList();
          setDoctors(response);
          setMessages(prevMessages => [...prevMessages, { text: `Doctor List:\n${response.map(d => `${d.name} (ID: ${d.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'addNewPatient':
          const patientName = prompt("Enter patient's name:");
          if (patientName) {
            response = await addNewPatient(patientName);
            setPatients(prevPatients => [...prevPatients, response]);
            setMessages(prevMessages => [...prevMessages, { text: `New patient added: ${response.name}. ID: ${response.id}`, sender: 'bot' }]);
          }
          break;
        case 'addNewNurse':
          const nurseName = prompt("Enter nurse's name:");
          if (nurseName) {
            response = await addNewNurse(nurseName);
            setNurses(prevNurses => [...prevNurses, response]);
            setMessages(prevMessages => [...prevMessages, { text: `New nurse added: ${response.name}. ID: ${response.id}`, sender: 'bot' }]);
          }
          break;
        case 'addNewDoctor':
          const doctorName = prompt("Enter doctor's name:");
          if (doctorName) {
            response = await addNewDoctor(doctorName);
            setDoctors(prevDoctors => [...prevDoctors, response]);
            setMessages(prevMessages => [...prevMessages, { text: `New doctor added: ${response.name}. ID: ${response.id}`, sender: 'bot' }]);
          }
          break;
        case 'viewNurseReports':
          response = await viewNurseReports();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse Reports:\n${response.map(r => `Nurse ${r.nurseId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'viewDoctorReports':
          response = await viewDoctorReports();
          setMessages(prevMessages => [...prevMessages, { text: `Doctor Reports:\n${response.map(r => `Doctor ${r.doctorId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'assignPatientToNurse':
          const patientId = prompt("Enter patient ID:");
          const nurseId = prompt("Enter nurse ID:");
          if (patientId && nurseId) {
            response = await assignPatientToNurse(patientId, nurseId);
            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
          }
          break;
        case 'assignNurseToDoctor':
          const assignNurseId = prompt("Enter nurse ID:");
          const doctorId = prompt("Enter doctor ID:");
          if (assignNurseId && doctorId) {
            response = await assignNurseToDoctor(assignNurseId, doctorId);
            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
          }
          break;
        case 'chatWithPatient':
          const chatPatientId = prompt("Enter patient ID to chat with:");
          if (chatPatientId) {
            response = await chatWithPatient(chatPatientId);
            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
          }
          break;
        case 'chatWithNurse':
          const chatNurseId = prompt("Enter nurse ID to chat with:");
          if (chatNurseId) {
            response = await chatWithNurse(chatNurseId);
            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
          }
          break;
        case 'chatWithDoctor':
          const chatDoctorId = prompt("Enter doctor ID to chat with:");
          if (chatDoctorId) {
            response = await chatWithDoctor(chatDoctorId);
            setMessages(prevMessages => [...prevMessages, { text: response, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "An error occurred. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="keza-admin-dashboard">
      <div className="keza-sidebar">
        <div className="keza-sidebar-header">
          <h2>Keza Health Bot Doctor</h2>
          <p>You can manage, monitor your Nurses, doctors and Patient within this platform</p>
        </div>
        <div className="keza-sidebar-menu">
          <button onClick={() => handleAction('getNurseList')}><Users size={20} className="keza-icon" /> List of Nurses</button>
          <button onClick={() => handleAction('getDoctorList')}><Users size={20} className="keza-icon" /> List of Doctors</button>
          <button onClick={() => handleAction('addNewPatient')}><UserPlus size={20} className="keza-icon" /> Add a new Patient</button>
          <button onClick={() => handleAction('addNewNurse')}><UserPlus size={20} className="keza-icon" /> Add a new Nurse</button>
          <button onClick={() => handleAction('addNewDoctor')}><UserPlus size={20} className="keza-icon" /> Add a new Doctor</button>
          <button onClick={() => handleAction('viewNurseReports')}><FileText size={20} className="keza-icon" /> View Nurses Reports</button>
          <button onClick={() => handleAction('viewDoctorReports')}><FileText size={20} className="keza-icon" /> View Doctors Reports</button>
          <button onClick={() => handleAction('assignPatientToNurse')}><Edit size={20} className="keza-icon" /> Assign Patient to Nurse</button>
          <button onClick={() => handleAction('assignNurseToDoctor')}><Edit size={20} className="keza-icon" /> Assign a Nurse to Doctor</button>
          <button onClick={() => handleAction('chatWithPatient')}><MessageSquare size={20} className="keza-icon" /> Chat with Patient</button>
          <button onClick={() => handleAction('chatWithNurse')}><MessageSquare size={20} className="keza-icon" /> Chat with Nurse</button>
          <button onClick={() => handleAction('chatWithDoctor')}><MessageSquare size={20} className="keza-icon" /> Chat with a Doctor</button>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} className="keza-icon" /> Switch Language</button>
        </div>
      </div>
      <div className="keza-chat-container">
        <div className="keza-chat-header">
          <h1>Keza Health Admin Dashboard</h1>
        </div>
        <div className="keza-chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`keza-message ${message.sender}`}>
              <div className="keza-message-bubble">
                {message.text}
              </div>
            </div>
          ))}
          {loading && (
            <div className="keza-message bot">
              <div className="keza-message-bubble keza-loading">
                <span className="keza-dot"></span>
                <span className="keza-dot"></span>
                <span className="keza-dot"></span>
              </div>
            </div>
          )}
        </div>
        <div className="keza-chat-input">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder={language === 'english' ? "Type your message or 'code:' followed by your patient code..." : "Andika ubutumwa bwawe..."}
            aria-label="Message input"
          />
          <button onClick={handleSendMessage} disabled={loading} aria-label="Send message">
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

// Simulated API functions
const simulateApiCall = async (message) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Simulated response to: "${message}"`;
};

const getNurseList = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [
    { id: 1, name: 'Nurse A' },
    { id: 2, name: 'Nurse B' },
    { id: 3, name: 'Nurse C' },
  ];
};

const getDoctorList = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [
    { id: 1, name: 'Doctor X' },
    { id: 2, name: 'Doctor Y' },
    { id: 3, name: 'Doctor Z' },
  ];
};

const addNewPatient = async (name) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return { id: Math.floor(Math.random() * 1000) + 1, name };
};

const addNewNurse = async (name) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return { id: Math.floor(Math.random() * 1000) + 1, name };
};

const addNewDoctor = async (name) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return { id: Math.floor(Math.random() * 1000) + 1, name };
};

const viewNurseReports = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [
    { nurseId: 1, report: 'Performed routine check-ups' },
    { nurseId: 2, report: 'Administered medications' },
    { nurseId: 3, report: 'Assisted in minor surgery' },
  ];
};

const viewDoctorReports = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [
    { doctorId: 1, report: 'Conducted patient consultations' },
    { doctorId: 2, report: 'Performed surgery' },
    { doctorId: 3, report: 'Reviewed test results' },
  ];
};

const assignPatientToNurse = async (patientId, nurseId) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Patient ${patientId} assigned to Nurse ${nurseId}`;
};

const assignNurseToDoctor = async (nurseId, doctorId) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Nurse ${nurseId} assigned to Doctor ${doctorId}`;
};

const chatWithPatient = async (patientId) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Chat initiated with Patient ${patientId}`;
};

const chatWithNurse = async (nurseId) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Chat initiated with Nurse ${nurseId}`;
};

const chatWithDoctor = async (doctorId) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return `Chat initiated with Doctor ${doctorId}`;
};

function getRandomGreeting(lang) {
  const greetings = {
    english: [
      "Hi there! I'm the Keza AI assistant specializing in Rwanda's abortion policy. How can I assist you today?",
      "Welcome to Keza Health Bot Admin. How may I help you with Rwanda's abortion policy?",
      "Greetings! I'm here to provide information about Rwanda's abortion policy. What would you like to know?",
    ],
    kinyarwanda: [
      "Muraho! Ndi umufasha wa Keza AI wishingikirije ku mabwiriza y'uburenganzira bwo guhagarika inda mu Rwanda. Ni gute nabafasha uyu munsi?",
      "Murakaza neza kuri Keza Health Bot Admin. Ni gute nabafasha ku bijyanye n'amabwiriza y'uburenganzira bwo guhagarika inda mu Rwanda?",
      "Mwaramutse! Ndi hano kugira ngo mbabwire amakuru ajyanye n'amabwiriza y'uburenganzira bwo guhagarika inda mu Rwanda. Ni iki mwifuza kumenya?",
    ]
  };
  return greetings[lang][Math.floor(Math.random() * greetings[lang].length)];
}

export default KezaAdminDashboard;
