// adminService.js
import axios from 'axios';

const USE_MOCK_DATA = true; // Set this to false to use real API calls

// Mock data for testing
const mockData = {
  nurses: [
    { id: '1', name: 'Nurse Alice', patients: ['1', '2'] },
    { id: '2', name: 'Nurse Bob', patients: ['3', '4'] },
    { id: '3', name: 'Nurse Carol', patients: ['5'] }
  ],
  doctors: [
    { id: '1', name: 'Dr. David', nurses: ['1', '2'] },
    { id: '2', name: 'Dr. Emma', nurses: ['3'] }
  ],
  patients: [
    { id: '1', name: 'Jane Doe', nurseId: '1' },
    { id: '2', name: 'John Smith', nurseId: '1' },
    { id: '3', name: 'Alice Johnson', nurseId: '2' },
    { id: '4', name: 'Bob Brown', nurseId: '2' },
    { id: '5', name: 'Carol White', nurseId: '3' }
  ],
  nurseReports: [
    { nurseId: '1', report: 'Completed all patient check-ins for the day.' },
    { nurseId: '2', report: 'Two patients required additional care.' },
    { nurseId: '3', report: 'All patients stable, no issues to report.' }
  ],
  doctorReports: [
    { doctorId: '1', report: 'Conducted telemedicine sessions with 5 patients.' },
    { doctorId: '2', report: 'Reviewed and updated treatment plans for high-risk patients.' }
  ]
};

const getErrorMessage = (language, errorType = 'invalid') => {
  if (errorType === 'invalid') {
    return language === 'english'
      ? "Invalid input. Please try again."
      : "Ibyo mwinjije sibyo. Nyamuneka, mwongere mugerageze.";
  } else if (errorType === 'api') {
    return language === 'english'
      ? "Unable to retrieve information. Please try again later."
      : "Ntabwo byashobotse kubona amakuru. Nyamuneka, mwongere mugerageze nyuma.";
  }
};

export const getNurseList = async () => {
  console.log('Attempting to get nurse list');
  try {
    let nurses;
    if (USE_MOCK_DATA) {
      nurses = mockData.nurses;
      console.log('Using mock data for nurse list');
    } else {
      const response = await axios.get('/api/nurses');
      console.log('API response:', response.data);
      nurses = response.data;
    }
    return nurses;
  } catch (error) {
    console.error('Error getting nurse list:', error);
    throw new Error('Unable to retrieve nurse list. Please try again later.');
  }
};

export const getPatientList = async () => {
  console.log('Attempting to get patient list');
  try {
    let patients;
    if (USE_MOCK_DATA) {
      patients = mockData.patients;
      console.log('Using mock data for patient list');
    } else {
      const response = await axios.get('/api/patients');
      console.log('API response:', response.data);
      patients = response.data;
    }
    return patients;
  } catch (error) {
    console.error('Error getting patient list:', error);
    throw new Error('Unable to retrieve patient list. Please try again later.');
  }
};

export const addNewPatient = async (patientName) => {
  console.log(`Attempting to add new patient: ${patientName}`);
  try {
    let newPatient;
    if (USE_MOCK_DATA) {
      newPatient = { id: (mockData.patients.length + 1).toString(), name: patientName, nurseId: null };
      mockData.patients.push(newPatient);
      console.log('Added new patient to mock data');
    } else {
      const response = await axios.post('/api/patients', { name: patientName });
      console.log('API response:', response.data);
      newPatient = response.data;
    }
    return newPatient;
  } catch (error) {
    console.error('Error adding new patient:', error);
    throw new Error('Unable to add new patient. Please try again later.');
  }
};

export const addNewNurse = async (nurseName) => {
  console.log(`Attempting to add new nurse: ${nurseName}`);
  try {
    let newNurse;
    if (USE_MOCK_DATA) {
      newNurse = { id: (mockData.nurses.length + 1).toString(), name: nurseName, patients: [] };
      mockData.nurses.push(newNurse);
      console.log('Added new nurse to mock data');
    } else {
      const response = await axios.post('/api/nurses', { name: nurseName });
      console.log('API response:', response.data);
      newNurse = response.data;
    }
    return newNurse;
  } catch (error) {
    console.error('Error adding new nurse:', error);
    throw new Error('Unable to add new nurse. Please try again later.');
  }
};

export const addNewDoctor = async (doctorName) => {
  console.log(`Attempting to add new doctor: ${doctorName}`);
  try {
    let newDoctor;
    if (USE_MOCK_DATA) {
      newDoctor = { id: (mockData.doctors.length + 1).toString(), name: doctorName, nurses: [] };
      mockData.doctors.push(newDoctor);
      console.log('Added new doctor to mock data');
    } else {
      const response = await axios.post('/api/doctors', { name: doctorName });
      console.log('API response:', response.data);
      newDoctor = response.data;
    }
    return newDoctor;
  } catch (error) {
    console.error('Error adding new doctor:', error);
    throw new Error('Unable to add new doctor. Please try again later.');
  }
};

export const viewNurseReports = async () => {
  console.log('Attempting to view nurse reports');
  try {
    let reports;
    if (USE_MOCK_DATA) {
      reports = mockData.nurseReports;
      console.log('Using mock data for nurse reports');
    } else {
      const response = await axios.get('/api/nurse-reports');
      console.log('API response:', response.data);
      reports = response.data;
    }
    return reports;
  } catch (error) {
    console.error('Error viewing nurse reports:', error);
    throw new Error('Unable to retrieve nurse reports. Please try again later.');
  }
};

export const viewDoctorReports = async () => {
  console.log('Attempting to view doctor reports');
  try {
    let reports;
    if (USE_MOCK_DATA) {
      reports = mockData.doctorReports;
      console.log('Using mock data for doctor reports');
    } else {
      const response = await axios.get('/api/doctor-reports');
      console.log('API response:', response.data);
      reports = response.data;
    }
    return reports;
  } catch (error) {
    console.error('Error viewing doctor reports:', error);
    throw new Error('Unable to retrieve doctor reports. Please try again later.');
  }
};

export const assignPatientToNurse = async (nurseId, patientId) => {
  console.log(`Attempting to assign patient ${patientId} to nurse ${nurseId}`);
  try {
    if (USE_MOCK_DATA) {
      const nurse = mockData.nurses.find(n => n.id === nurseId);
      const patient = mockData.patients.find(p => p.id === patientId);
      if (nurse && patient) {
        nurse.patients.push(patientId);
        patient.nurseId = nurseId;
        console.log('Assigned patient to nurse in mock data');
      } else {
        throw new Error('Nurse or patient not found');
      }
    } else {
      await axios.post('/api/assign-patient', { nurseId, patientId });
      console.log('Patient assigned to nurse via API');
    }
    return { success: true, message: `Patient ${patientId} assigned to nurse ${nurseId}` };
  } catch (error) {
    console.error('Error assigning patient to nurse:', error);
    throw new Error('Unable to assign patient to nurse. Please try again later.');
  }
};

export const assignNurseToDoctor = async (doctorId, nurseId) => {
  console.log(`Attempting to assign nurse ${nurseId} to doctor ${doctorId}`);
  try {
    if (USE_MOCK_DATA) {
      const doctor = mockData.doctors.find(d => d.id === doctorId);
      const nurse = mockData.nurses.find(n => n.id === nurseId);
      if (doctor && nurse) {
        doctor.nurses.push(nurseId);
        console.log('Assigned nurse to doctor in mock data');
      } else {
        throw new Error('Doctor or nurse not found');
      }
    } else {
      await axios.post('/api/assign-nurse', { doctorId, nurseId });
      console.log('Nurse assigned to doctor via API');
    }
    return { success: true, message: `Nurse ${nurseId} assigned to doctor ${doctorId}` };
  } catch (error) {
    console.error('Error assigning nurse to doctor:', error);
    throw new Error('Unable to assign nurse to doctor. Please try again later.');
  }
};

export const chatWithPatient = async (patientId) => {
  console.log(`Initiating chat with patient ${patientId}`);
  // This is a placeholder function. In a real application, you would implement
  // the logic to start a chat session with the patient.
  return `Chat session initiated with patient ${patientId}`;
};

export const chatWithNurse = async (nurseId) => {
  console.log(`Initiating chat with nurse ${nurseId}`);
  // This is a placeholder function. In a real application, you would implement
  // the logic to start a chat session with the nurse.
  return `Chat session initiated with nurse ${nurseId}`;
};

export const chatWithDoctor = async (doctorId) => {
  console.log(`Initiating chat with doctor ${doctorId}`);
  // This is a placeholder function. In a real application, you would implement
  // the logic to start a chat session with the doctor.
  return `Chat session initiated with doctor ${doctorId}`;
};

export const getAdminBotResponse = async (message, language) => {
  // This function would contain the logic for the admin chatbot to process messages
  // and return appropriate responses. For now, it's a simple echo.
  return `Admin bot received: ${message}`;
};