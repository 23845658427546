import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import KezaHealthChatbot from './components/PatientChatbot/KezaHealthChatbot';
import PatientDashboard from './components/PatientChatbot/Webversion/PatientDashboard';
import NurseChatbot from './components/NurseDashboard/NurseChatbot';
import NurseDashboard from './components/NurseDashboard/Webversionnurse/NurseDashboard';
import AdminChatbot from './components/AdminDashboard/AdminChatbot';
import AdminDashboard from './components/AdminDashboard/Web Version/AdminDashboard';
import DoctorChatbot from './components/DoctorDashboard/DoctorChatbot';
import DoctorDashboard from './components/DoctorDashboard/WebversionDoctor/DoctorDashboard';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/patient/chatbot" element={<KezaHealthChatbot />} />
          <Route path="/patient/dashboard" element={<PatientDashboard />} />
          <Route path="/nurse/chatbot" element={<NurseChatbot />} />
          <Route path="/nurse/dashboard" element={<NurseDashboard />} />
          <Route path="/admin/chatbot" element={<AdminChatbot />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/doctor/chatbot" element={<DoctorChatbot />} />
          <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;