// Mock data storage
let patients = [];
let nurses = [
  { id: 1, name: "Nurse Joy" },
  { id: 2, name: "Nurse Jenny" }
];
let nurseReports = [
  { nurseId: 1, report: "All patients are doing well." },
  { nurseId: 2, report: "Some patients need further follow-up." }
];

const generateUniqueCode = () => {
  return Math.random().toString(36).substr(2, 8).toUpperCase();
};

export const getNurseBotResponse = async (message, language) => {
  // Implement more sophisticated bot logic here
  if (message.toLowerCase().includes('add patient')) {
    return "To add a new patient, please click the 'Add New Patient' button.";
  } else if (message.toLowerCase().includes('view patients')) {
    return "To view the patient list, please click the 'View Patient List' button.";
  } else if (message.toLowerCase().includes('update record')) {
    return "To update a patient's record, please click the 'Update Patient Record' button.";
  }
  return "I'm here to help you manage patients. You can add new patients, view patient lists, or update patient records.";
};

export const addNewPatient = async (name) => {
  const newPatient = {
    id: patients.length + 1,
    name: name,
    code: generateUniqueCode(),
    records: [],
    medications: [],
    appointments: []
  };
  patients.push(newPatient);
  return newPatient;
};

export const getPatientList = async () => {
  return patients;
};

export const updatePatientRecord = async (patientCode, updateInfo) => {
  const patient = patients.find(p => p.code === patientCode);
  if (patient) {
    patient.records.push({
      date: new Date().toISOString(),
      info: updateInfo
    });
    return true;
  }
  return false;
};

export const scheduleFollowUp = async (patientCode, date) => {
  const patient = patients.find(p => p.code === patientCode);
  if (patient) {
    patient.appointments.push(date);
    return true;
  }
  return false;
};

export const addMedication = async (patientCode, medication) => {
  const patient = patients.find(p => p.code === patientCode);
  if (patient) {
    patient.medications.push(medication);
    return true;
  }
  return false;
};

// New functions
export const getDoctorBotResponse = async (message, language) => {
  // Implement more sophisticated bot logic here
  return "This is a response from the doctor bot.";
};

export const getNurseList = async () => {
  return nurses;
};

export const getNursePatients = async (nurseId) => {
  // For simplicity, let's return all patients as if they belong to the nurse
  return patients;
};

export const addNewNurse = async (name) => {
  const newNurse = {
    id: nurses.length + 1,
    name: name
  };
  nurses.push(newNurse);
  return newNurse;
};

export const viewNurseReports = async () => {
  return nurseReports;
};

export const assignPatientToNurse = async (nurseId, patientId) => {
  // Logic to assign a patient to a nurse (not implemented here)
  return true;
};

export const chatWithPatient = async (patientId) => {
  // Logic to chat with a patient (not implemented here)
  return `Chatting with patient ID ${patientId}.`;
};
