// kinyarwandaContent.js

const kinyarwandaContent = {
    greetings: [
      "Muraho! Ndi keza health chatbot. Nshobora kugufasha ute ku makuru yerekeye politiki y'u Rwanda ku byerekeye gukuramo inda?",
      "Murakaza neza kuri keza health! Ndi hano kugira ngo mbasubize ibibazo ku bijyanye na politiki y'u Rwanda ku byerekeye gukuramo inda. Ni iki wifuza kumenya?",
      "Amakuru! Ndi umufasha wa keza health w'ubwenge buhanga wibanda kuri politiki y'u Rwanda ku byerekeye gukuramo inda. Wibwire umbaze icyo ushaka kuyirebana."
    ],
    generalResponses: {
      hi: "Muraho! Nshobora kugufasha iki uyu munsi?",
      hello: "Amakuru! Ni iki wifuza kumenya ku bijyanye na politiki y'u Rwanda ku byerekeye gukuramo inda?",
      hey: "Yego! Ndi hano kugufasha. Ni ibihe bibazo ufite?",
      thanks: "Urakoze! Hari ikindi nakugirira?",
      bye: "Murabeho! Nuramuka ufite ibindi bibazo, ntutinye kubibaza.",
      unclear: "Sinsobanukiwe neza. Washobora kongera ukavuga ikibazo cyawe mu bundi buryo?"
    },
    allowedGrounds: [
      "Impamvu zemewe zo gukuramo inda mu Rwanda ni izi zikurikira:",
      "1. Kuba umuntu utwite ari umwana",
      "2. Kuba usaba gukurirwamo inda yarakoreshejwe imibonano mpuzabitsina ku gahato",
      "3. Kuba usaba gukurirwamo inda yarayitwaye nyuma yo kubanishwa ku gahato n'undi nk'umugore n'umugabo",
      "4. Kuba usaba gukurirwamo inda yarayitewe n'uwo bafitanye isano ya hafi kugera ku gisanira cya kabiri",
      "5. Kuba inda ibangamiye ubuzima bw'utwite cyangwa ubw'umwana atwite"
    ],
    gestationLimit: "Uretse mu gihe inda ibangamiye ubuzima bw'utwite cyangwa ubw'umwana atwite, inda ikurwamo igomba kuba itarengeje ibyumweru makumyabiri na bibiri (22).",
    eligibleFacility: "Gukuriramo umuntu inda bikorerwa mu kigo cy'ubuvuzi cya Leta cyangwa icyigenga kiri ku rwego rw'ibitaro cyangwa urwa polikilinike, cyemerewe gukora na Minisitiri ufite ubuzima mu nshingano ze.",
    confidentiality: "Umuganga n'ikigo cy'ubuvuzi bakiriye umuntu usaba gukurirwamo inda bagomba kwita ku iyubahirizwa ry'uburenganzira bwo kugirirwa ibanga.",
    default: "Mbabarira, nta makuru yihariye mfite kuri icyo kibazo. Ushobora kubaza ku bijyanye n'impamvu zemewe, igihe ntarengwa, ibigo byemewe, cyangwa ibanga.",
    specificResponses: {
      child: "Niba utwite ari umwana (ufite imyaka iri munsi ya 18), afite uburenganzira bwo gukuramo inda. Ibi bikorwa kugira ngo kurinde abana ingaruka mbi ku buzima bwabo zaterwa no gutwita bakiri bato no kubyara. Ukwemera k'umwana kuritabwaho nubwo uhagarariye umwana wemewe n'amategeko yabyanga.",
      rape: "Mu gihe habayeho gufatwa ku ngufu, umuntu afite uburenganzira bwo gukuramo inda. Ibi byemera ko ari ikintu gikomeye kandi kibi cyabaye, bityo bigatanga uburenganzira bwo guhitamo ku bijyanye n'inda. Politiki ntisaba gutanga ibimenyetso by'uko yafashwe ku ngufu.",
      forcedMarriage: "Niba inda yatewe n'ishyingiranwa ry'agahato, gukuramo inda biremewe. Ibi byemera ko nta bwumvikane bwabayeho mu mibanire kandi birinda abantu guhatirwa gutwita bitifuza.",
      incest: "Inda zitewe n'imibonano mpuzabitsina hagati y'abafitanye isano ya hafi (kugeza ku gisanira cya kabiri) zishobora gukurwamo. Ibi biterwa n'ibibazo by'imibanire n'ingaruka mbi zishobora kuba ku buzima bw'umwana wavuka.",
      health: "Niba inda ishobora kugira ingaruka mbi ku buzima bw'utwite cyangwa bw'umwana atwite, gukuramo inda birashoboka. Ibi bireba ubuzima bw'umubiri n'ubwo mu mutwe. Muri ibi bihe, igipimo cy'ibyumweru 22 ntigikurikizwa.",
      consent: "Umuntu usaba gukurirwamo inda agomba gutanga uburenganzira bwe bwanditse nyuma yo gusobanurirwa byimbitse. Ku bana cyangwa abafite ubumuga bwo mu mutwe, uhagarariye uwo muntu mu mategeko niwe utanga uburenganzira. Ariko, niba uhagarariye uwo muntu abyanze, ukwemera k'umwana niko kwitabwaho.",
      preProcedure: "Mbere yo gukuramo inda, muganga agomba gutanga inama zimbitse mbere yo gukuramo inda no gukora isuzuma ryimbitse.",
      postProcedure: "Nyuma yo gukuramo inda, hagomba gutangwa serivisi zikwiye nyuma yo gukuramo inda, harimo no gutanga inama ku buryo bwo kuboneza urubyaro niba bikenewe.",
      cost: "Politiki ntivuga ku bijyanye n'amafaranga. Amafaranga ashobora gutandukana bitewe n'ikigo. Kugira ngo ubone amakuru yuzuye ku bijyanye n'amafaranga, ni byiza kubaza ikigo cy'ubuzima cyemewe.",
      legalConsequences: "Politiki yibanda ku gutanga uburyo bwizewe kandi bwemewe n'amategeko bwo gukuramo inda mu bihe byihariye. Ntivuga ku ngaruka z'amategeko zo gusaba gukuramo inda hakurikijwe aya mabwiriza.",
      conscientious: "Politiki ntivuga ku buryo bweruye ku bijyanye no kwanga gutanga iyi serivisi ku mpamvu z'umutima ku baganga. Ariko, ibigo byemewe bisabwa gutanga iyi serivisi iyo impamvu zuzuye.",
      accompaniment: "Politiki ntivuga ku mabwiriza yo kujyanwa n'undi muntu mu gihe cyo gukuramo inda. Ni byiza kubaza ikigo cy'ubuzima ku mabwiriza yabo bwite.",
      waitingPeriod: "Nta gihe cyo gutegereza gisabwa muri iyi politiki. Intego ni ugutanga ubufasha bwihuse iyo impamvu zo gukuramo inda zuzuye.",
      parental: "Ku bana bato (bari munsi y'imyaka 18), uburenganzira bw'uhagarariye umwana mu mategeko busanzwe busabwa. Ariko, niba babyanze, ukwemera k'umwana ubwe kwitabwaho.",
      medical: "Uburyo bwombi, ubw'imiti n'ubw'ubuganga, bushobora kuboneka, bitewe n'uko inda ingana n'ubushobozi bw'ikigo.",
      mentalHealth: "Ubuzima bwo mu mutwe bufatwa nk'igice cy'ubuzima bwose. Niba inda ishobora kugira ingaruka ku buzima bwo mu mutwe bw'utwite, ibi bishobora kuba impamvu yo gukuramo inda.",
      fatalConditions: "Niba hagaragaye ikibazo ku mwana utwite gishobora guteza ibibazo ku buzima bwe, ibi bishobora kuba impamvu yo gukuramo inda, ndetse no nyuma y'ibyumweru 22.",
      emergency: "Mu bihe byihutirwa aho ubuzima bw'utwite buri mu kaga gakomeye, ubuvuzi bwihutirwa buhabwa agaciro.",
      foreignNationals: "Politiki ntivuga ku mabwiriza atandukanye ku banyamahanga. Amabwiriza amwe agomba gukurikizwa ku bantu bose basaba serivisi zo gukuramo inda mu Rwanda.",
      referral: "Niba ikigo kidashobora gutanga ubufasha bukenewe, kigomba kohereza umurwayi ku kigo gikwiye gishobora gutanga serivisi zikenewe.",
      appeal: "Politiki ntivuga ku buryo bwo kujurira iyo uburenganzira bwo gukuramo inda bwanzwe. Muri ibyo bihe, gushaka inama ya kabiri ku kindi kigo cyemewe bishobora kuba uburyo.",
      records: "Inyandiko z'ubuvuzi zijyanye no gukuramo inda zigirwa ibanga, nk'izindi nyandiko z'ubuvuzi zose. Abakozi b'ubuvuzi bemewe gusa nibo bagomba kugera kuri izo nyandiko."
    },
    nurseContact: "Kugira ngo uvugane n'umuforomo, nyamuneka hamagara umurongo wacu w'ubufasha kuri +250787171273. Umukozi w'ubuzima azagufasha vuba.",
    commonQuestions: {
      "Ni izihe mpamvu zemewe zo gukuramo inda?": "allowedGrounds",
      "Ni igihe kingana iki nshobora gukuramo inda?": "gestationLimit",
      "Ni hehe nakurirwamo inda?": "eligibleFacility",
      "Ese gukuramo inda kwanjye biragirwa ibanga?": "confidentiality",
      "Ese umwana ashobora gukuramo inda?": "child",
      "Bite se niba nafashwe ku ngufu?": "rape",
      "Ese gushyingirwa ku gahato bituma umuntu ashobora gukuramo inda?": "forcedMarriage",
      "Ese imibonano n'uwo mufitanye isano ni impamvu yo gukuramo inda?": "incest",
      "Bite se niba inda ishobora kuba iyobangamiye ubuzima?": "health",
      "Ngomba gutanga uburenganzira?": "consent",
      "Ni iki kiba mbere yo gukuramo inda?": "preProcedure",
      "Bite se nyuma yo gukuramo inda?": "postProcedure",
      "Bitwara amafaranga angahe?": "cost",
      "Hari ingaruka z'amategeko?": "legalConsequences",
      "Abaganga bashobora kwanga gukuramo inda?": "conscientious",
      "Hari ushobora kunjyana?": "accompaniment",
      "Hari igihe cyo gutegereza?": "waitingPeriod",
      "Ababyeyi banjye bagomba kumenya?": "parental",
      "Ni ubuhe buryo bukoreshwa mu gukuramo inda?": "medical",
      "Ese ubuzima bwo mu mutwe burabazwa?": "mentalHealth",
      "Bite se niba umwana afite ikibazo gikomeye?": "fatalConditions",
      "Bite se mu bihe byihutirwa?": "emergency",
      "Sindi Umunyarwanda, nshobora gukuramo inda?": "foreignNationals",
      "Bite se niba ikigo cy'ubuzima cyo hafi yanjye kidashobora kumfasha?": "referral",
      "Nshobora kujurira niba bannyimye gukuramo inda?": "appeal",
      "Inyandiko zo gukuramo inda zibikwa gute?": "records"
    }
  };
  
  export default kinyarwandaContent;