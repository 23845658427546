import React, { useState, useEffect } from 'react';
import { Send, Globe, FileText, UserPlus, Users,  Calendar, PlusCircle } from 'lucide-react';
import { 
  addMedication, 
  addNewPatient, 
  getNurseBotResponse, 
  getPatientList, 
  scheduleFollowUp, 
  updatePatientRecord
} from '../../../services/nurseChatbotLogic';
import { getEmergencyContacts } from '../../../services/nurseService';
import './NurseDashboard.css';

const NurseDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    setMessages([{ text: getRandomGreeting(language), sender: 'bot' }]);
  }, [language]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');

    try {
      const botResponse = await getNurseBotResponse(inputMessage, language);
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    }
  };

  const handleAction = async (action) => {
    try {
      switch (action) {
        case 'addMedication':
          const medPatientCode = prompt("Enter patient code:");
          const medication = prompt("Enter medication details:");
          if (medPatientCode && medication) {
            await addMedication(medPatientCode, medication);
            setMessages(prevMessages => [...prevMessages, { text: `Added medication for patient with code: ${medPatientCode}`, sender: 'bot' }]);
          }
          break;
        case 'addNewPatient':
          const patientName = prompt("Enter patient's name:");
          if (patientName) {
            const result = await addNewPatient(patientName);
            setMessages(prevMessages => [...prevMessages, { text: `New patient added: ${result.name}. Code: ${result.code}`, sender: 'bot' }]);
          }
          break;
        case 'getPatientList':
          const patients = await getPatientList();
          setMessages(prevMessages => [...prevMessages, { text: `Patient List:\n${patients.map(p => `${p.name} (Code: ${p.code})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'scheduleFollowUp':
          const followUpCode = prompt("Enter patient code:");
          const followUpDate = prompt("Enter follow-up date (YYYY-MM-DD):");
          if (followUpCode && followUpDate) {
            await scheduleFollowUp(followUpCode, followUpDate);
            setMessages(prevMessages => [...prevMessages, { text: `Scheduled follow-up for patient with code: ${followUpCode} on ${followUpDate}`, sender: 'bot' }]);
          }
          break;
        case 'updatePatientRecord':
          const patientCode = prompt("Enter patient code:");
          const updateInfo = prompt("Enter update information:");
          if (patientCode && updateInfo) {
            await updatePatientRecord(patientCode, updateInfo);
            setMessages(prevMessages => [...prevMessages, { text: `Updated record for patient with code: ${patientCode}`, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        case 'emergencyContacts':
          try {
            const contactMessages = await getEmergencyContacts(language);
            const title = language === 'english' 
              ? "Emergency Contacts:\n\n" 
              : "Nimero za telefoni zihamagarwa mu bihe by'ubutabazi:\n\n";
            const actionMessage = title + Object.entries(contactMessages)
              .map(([key, value]) => `${key.toUpperCase()}: ${value}`)
              .join('\n\n');
            setMessages(prevMessages => [...prevMessages, { text: actionMessage, sender: 'bot' }]);
          } catch (error) {
            console.error('Error getting emergency contacts:', error);
            const errorMessage = language === 'english'
              ? "Unable to retrieve emergency contact information. Please try again later."
              : "Ntabwo byashobotse kubona amakuru y'umubare wa telefone mu gihe cy'ikibazo cya ngombwa. Nyamuneka, ongera ugerageze.";
            setMessages(prevMessages => [...prevMessages, { text: errorMessage, sender: 'bot' }]);
          }
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    }
  };

  return (
    <div className="keza-nurse-dashboard">
      <div className="keza-dashboard-header">
        <h1 className="keza-dashboard-title">Keza Health Nurse Dashboard</h1>
      </div>
      <div className="keza-dashboard-content">
        <div className="keza-sidebar">
          <h2>Keza Health Bot Nurse</h2>
          <p>You can manage, monitor your patient health within this platform</p>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} /> Switch Language</button>
          <button onClick={() => handleAction('emergencyContacts')}><FileText size={20} /> Emergency Contact</button>
          <button onClick={() => handleAction('getPatientList')}><Users size={20} /> Get Patient List</button>
          <button onClick={() => handleAction('scheduleFollowUp')}><Calendar size={20} /> Schedule a Followup</button>
          <button onClick={() => handleAction('addNewPatient')}><UserPlus size={20} /> Add New Patient</button>
          <button onClick={() => handleAction('addMedication')}><PlusCircle size={20} /> Add Medication</button>
        </div>
        <div className="keza-chat-area">
          <div className="keza-chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`keza-message ${message.sender}`}>
                <div className="keza-message-bubble">
                  {message.text}
                </div>
              </div>
            ))}
          </div>
          <div className="keza-chat-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder={language === 'english' ? "Type your message or 'code:' followed by your patient code..." : "Andika ubutumwa bwawe..."}
              aria-label="Message input"
            />
            <button onClick={handleSendMessage} aria-label="Send message">
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function getRandomGreeting(lang) {
  const greetings = {
    english: [
      "Greetings! I'm the keza health AI assistant focused on Rwanda's abortion policy. Feel free to ask me anything about it.",
      "Welcome to Keza Health Bot Nurse. How may I help you with information about Rwanda's abortion policy?",
      "Hello! I'm here to provide information about Rwanda's abortion policy. What would you like to know?",
      "Hi there! I'm the Keza AI assistant specializing in Rwanda's abortion policy. How can I assist you today?",
      "Good day! I'm here to answer your questions about Rwanda's abortion policy. What can I help you with?"
    ],

    kinyarwanda: [
      "Muraho! Ndi umufasha wa Keza Health AI wibanda ku mabwiriza y'uburenganzira bwo gutwita muri Rwanda. Mushobora kumbaza icyo mushaka kuri iyo politiki.",
      "Murakaza neza kuri Keza Health Bot Nurse. Ni gute nabafasha ku bijyanye n'amabwiriza y'uburenganzira bwo gutwita muri Rwanda?",
      "Mwaramutse! Ndi hano kugira ngo mbahe amakuru ku mabwiriza y'uburenganzira bwo gutwita muri Rwanda. Ni iki mwifuza kumenya?",
      "Mwiriwe! Ndi umufasha wa Keza AI wihariye ku mabwiriza y'uburenganzira bwo gutwita muri Rwanda. Ni gute nabafasha uyu munsi?",
      "Mwaramutse neza! Ndi hano kugira ngo msubize ibibazo byanyu ku mabwiriza y'uburenganzira bwo gutwita muri Rwanda. Ni iki nabafasha?"
    ]
  };
  return greetings[lang][Math.floor(Math.random() * greetings[lang].length)];
}

export default NurseDashboard;