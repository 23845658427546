import React, { useState, useEffect } from 'react';
import { Send, Globe, FileText, UserPlus, Users, Edit, Calendar, MessageSquare } from 'lucide-react';

import {
  getNurseList,
  getNursePatients,
  addNewNurse,
  scheduleFollowUp,
  viewNurseReports,
  assignPatientToNurse,
  chatWithPatient,
  getDoctorBotResponse
} from '../../../services/nurseChatbotLogic';
import './DoctorDashboard.css';

const DoctorDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    setMessages([{ text: "Hi there! I'm the Keza AI assistant specializing in Rwanda's abortion policy. How can I assist you today?", sender: 'bot' }]);
  }, []);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setLoading(true);

    try {
      const botResponse = await getDoctorBotResponse(inputMessage, language);
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setLoading(true);
    try {
      switch (action) {
        case 'getNurseList':
          const nurses = await getNurseList();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse List:\n${nurses.map(n => `${n.name} (ID: ${n.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'getNursePatients':
          const nurseId = prompt("Enter nurse ID:");
          if (nurseId) {
            const patients = await getNursePatients(nurseId);
            setMessages(prevMessages => [...prevMessages, { text: `Patients for Nurse ${nurseId}:\n${patients.map(p => `${p.name} (ID: ${p.id})`).join('\n')}`, sender: 'bot' }]);
          }
          break;
        case 'addNewNurse':
          const nurseName = prompt("Enter nurse's name:");
          if (nurseName) {
            const result = await addNewNurse(nurseName);
            setMessages(prevMessages => [...prevMessages, { text: `New nurse added: ${result.name}. ID: ${result.id}`, sender: 'bot' }]);
          }
          break;
        case 'scheduleFollowUp':
          const patientId = prompt("Enter patient ID:");
          const followUpDate = prompt("Enter follow-up date (YYYY-MM-DD):");
          if (patientId && followUpDate) {
            await scheduleFollowUp(patientId, followUpDate);
            setMessages(prevMessages => [...prevMessages, { text: `Scheduled follow-up for patient with ID: ${patientId} on ${followUpDate}`, sender: 'bot' }]);
          }
          break;
        case 'viewNurseReports':
          const reports = await viewNurseReports();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse Reports:\n${reports.map(r => `Nurse ${r.nurseId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'assignPatientToNurse':
          const assignNurseId = prompt("Enter nurse ID:");
          const assignPatientId = prompt("Enter patient ID:");
          if (assignNurseId && assignPatientId) {
            await assignPatientToNurse(assignNurseId, assignPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Assigned patient ${assignPatientId} to nurse ${assignNurseId}`, sender: 'bot' }]);
          }
          break;
        case 'chatWithPatient':
          const chatPatientId = prompt("Enter patient ID to chat with:");
          if (chatPatientId) {
            const chatResult = await chatWithPatient(chatPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Chat initiated with patient ${chatPatientId}. ${chatResult}`, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="keza-doctor-dashboard">
      <div className="keza-dashboard-header">
        <h1>Keza Health Doctor Dashboard</h1>
      </div>
      <div className="keza-dashboard-content">
        <div className="keza-sidebar">
          <h2>Keza Health Bot Doctor</h2>
          <p>You can manage, monitor your Nurses within this platform</p>
          <button onClick={() => handleAction('getNurseList')}><Users size={20} /> List of Nurses</button>
          <button onClick={() => handleAction('getNursePatients')}><Users size={20} /> Nurse's Patients</button>
          <button onClick={() => handleAction('addNewNurse')}><UserPlus size={20} /> Add a new Nurse</button>
          <button onClick={() => handleAction('scheduleFollowUp')}><Calendar size={20} /> Schedule a Follow-up</button>
          <button onClick={() => handleAction('viewNurseReports')}><FileText size={20} /> View Nurses Reports</button>
          <button onClick={() => handleAction('assignPatientToNurse')}><Edit size={20} /> Assign Patient to Nurse</button>
          <button onClick={() => handleAction('chatWithPatient')}><MessageSquare size={20} /> Chat with Patient</button>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} /> Switch Language</button>
        </div>
        <div className="keza-chat-area">
          <div className="keza-chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`keza-message ${message.sender}`}>
                <div className="keza-message-bubble">
                  {message.text}
                </div>
              </div>
            ))}
            {loading && (
              <div className="keza-message bot">
                <div className="keza-message-bubble keza-loading">
                  <span className="keza-dot"></span>
                  <span className="keza-dot"></span>
                  <span className="keza-dot"></span>
                </div>
              </div>
            )}
          </div>
          <div className="keza-chat-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Type your message or 'code:' followed by your patient code..."
              aria-label="Message input"
            />
            <button onClick={handleSendMessage} disabled={loading} aria-label="Send message">
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDashboard;