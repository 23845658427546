import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { User, Users, Stethoscope, UserCog, Lock } from 'lucide-react';
import './LandingPage.css';

const NavBar = () => (
  <nav className="navbar">
    <div className="container">
      <h1 className="logo">KezaHealth</h1>
      <div>
        <a href="#" className="nav-link">About</a>
        <a href="#" className="nav-link">Services</a>
        <a href="#" className="nav-link">Contact</a>
      </div>
    </div>
  </nav>
);

const UserSection = ({ title, icon: Icon, description, chatbotLink, dashboardLink }) => (
  <div className="user-section">
    <Icon className="user-section-icon" />
    <h2 className="user-section-title">{title}</h2>
    <p className="user-section-description">{description}</p>
    <div className="user-section-buttons">
      <Link to={chatbotLink} className="button button-primary">
        Chatbot
      </Link>
      <Link to={dashboardLink} className="button button-secondary">
        Dashboard
      </Link>
    </div>
  </div>
);

const Footer = () => (
  <footer className="footer">
    <div className="footer-content">
      <p>&copy; 2024 KezaHealth. All rights reserved.</p>
    </div>
  </footer>
);

const generateMathQuestion = () => {
  const operations = ['+', '-', '*'];
  const operation = operations[Math.floor(Math.random() * operations.length)];
  const num1 = Math.floor(Math.random() * 10) + 1;
  const num2 = Math.floor(Math.random() * 10) + 1;
  
  let question, answer;
  switch (operation) {
    case '+':
      question = `${num1} + ${num2}`;
      answer = num1 + num2;
      break;
    case '-':
      question = `${num1 + num2} - ${num1}`;
      answer = num2;
      break;
    case '*':
      question = `${num1} * ${num2}`;
      answer = num1 * num2;
      break;
  }
  
  return { question, answer };
};

const SecurityCheck = ({ onSuccess }) => {
  const [mathQuestion, setMathQuestion] = useState({ question: '', answer: 0 });
  const [userAnswer, setUserAnswer] = useState('');
  const [message, setMessage] = useState('');
  const [showSecretKeyInput, setShowSecretKeyInput] = useState(false);
  const [secretKey, setSecretKey] = useState('');

  useEffect(() => {
    setMathQuestion(generateMathQuestion());
  }, []);

  const handleMathSubmit = (e) => {
    e.preventDefault();
    if (parseInt(userAnswer) === mathQuestion.answer) {
      setMessage("You're a genius! Correct answer. Now, please enter the secret key.");
      setShowSecretKeyInput(true);
    } else {
      setMessage("Oops! That's not correct. Try again!");
      setMathQuestion(generateMathQuestion());
      setUserAnswer('');
    }
  };

  const handleSecretKeySubmit = (e) => {
    e.preventDefault();
    if (secretKey === 'chickenleg') {
      onSuccess();
    } else {
      setMessage("Wrong secret key. Access denied.");
    }
  };

  return (
    <div className="security-check">
      <Lock className="security-icon" />
      <h2>Security Check</h2>
      {!showSecretKeyInput ? (
        <form onSubmit={handleMathSubmit}>
          <p>Please solve this math question:</p>
          <p className="math-question">{mathQuestion.question} = ?</p>
          <input
            type="number"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            required
            placeholder="Enter your answer"
          />
          <button type="submit">Submit Answer</button>
        </form>
      ) : (
        <form onSubmit={handleSecretKeySubmit}>
          <p>Enter the secret key:</p>
          <input
            type="password"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
            required
            placeholder="Secret key"
          />
          <button type="submit">Submit Secret Key</button>
        </form>
      )}
      {message && <p className="message">{message}</p>}
    </div>
  );
};

const LandingPage = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  if (!isAuthorized) {
    return <SecurityCheck onSuccess={() => setIsAuthorized(true)} />;
  }

  return (
    <div className="landing-page">
      <NavBar />
      <div className="content-wrapper">
        <main className="main-content">
          <h1 className="main-title">Welcome to KezaHealth</h1>
          <p className="main-subtitle">Your comprehensive healthcare management solution</p>
          <div className="user-sections">
            <UserSection
              title="Patient"
              icon={User}
              description="Access your health records, schedule appointments, and chat with healthcare providers."
              chatbotLink="/patient/chatbot"
              dashboardLink="/patient/dashboard"
            />
            <UserSection
              title="Nurse"
              icon={Users}
              description="Manage patient care, access medical records, and collaborate with the healthcare team."
              chatbotLink="/nurse/chatbot"
              dashboardLink="/nurse/dashboard"
            />
            <UserSection
              title="Doctor"
              icon={Stethoscope}
              description="Review patient information, update treatment plans, and communicate with your team."
              chatbotLink="/doctor/chatbot"
              dashboardLink="/doctor/dashboard"
            />
            <UserSection
              title="Admin"
              icon={UserCog}
              description="Oversee system operations, manage user accounts, and ensure smooth functionality."
              chatbotLink="/admin/chatbot"
              dashboardLink="/admin/dashboard"
            />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;