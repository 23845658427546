// questionStore.js

let storedQuestions = [];

export function storeQuestion(question, language) {
  storedQuestions.push({ question, language, timestamp: new Date().toISOString() });
  
  // Here you would typically send this data to a server or save it locally
  console.log('Stored question:', { question, language, timestamp: new Date().toISOString() });
}

export function getStoredQuestions() {
  return storedQuestions;
}

// You can add more functions here to handle the stored questions, such as:
// - Sending them to a server in batches
// - Saving them to local storage
// - Clearing the stored questions after they've been processed