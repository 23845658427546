import React, { useState, useEffect } from 'react';
import { Send, Menu, X, Globe, FileText, UserPlus, Users, Edit, Calendar, PlusCircle } from 'lucide-react';

import { 
  addMedication, 
  addNewPatient, 
  getNurseBotResponse, 
  getPatientList, 
  scheduleFollowUp, 
  updatePatientRecord
} from '../../services/nurseChatbotLogic';
import { getEmergencyContacts } from '../../services/nurseService'; // Corrected import
import './MobileNurseChatbot.css';

const NurseDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    setMessages([{ text: getRandomGreeting(language), sender: 'bot' }]);
  }, [language]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setLoading(true);

    try {
      const botResponse = await getNurseBotResponse(inputMessage, language);
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setIsMenuOpen(false);
    setLoading(true);
    try {
      switch (action) {
        case 'addMedication':
          const medPatientCode = prompt("Enter patient code:");
          const medication = prompt("Enter medication details:");
          if (medPatientCode && medication) {
            await addMedication(medPatientCode, medication);
            setMessages(prevMessages => [...prevMessages, { text: `Added medication for patient with code: ${medPatientCode}`, sender: 'bot' }]);
          }
          break;
        case 'addNewPatient':
          const patientName = prompt("Enter patient's name:");
          if (patientName) {
            const result = await addNewPatient(patientName);
            setMessages(prevMessages => [...prevMessages, { text: `New patient added: ${result.name}. Code: ${result.code}`, sender: 'bot' }]);
          }
          break;
        case 'getPatientList':
          const patients = await getPatientList();
          setMessages(prevMessages => [...prevMessages, { text: `Patient List:\n${patients.map(p => `${p.name} (Code: ${p.code})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'scheduleFollowUp':
          const followUpCode = prompt("Enter patient code:");
          const followUpDate = prompt("Enter follow-up date (YYYY-MM-DD):");
          if (followUpCode && followUpDate) {
            await scheduleFollowUp(followUpCode, followUpDate);
            setMessages(prevMessages => [...prevMessages, { text: `Scheduled follow-up for patient with code: ${followUpCode} on ${followUpDate}`, sender: 'bot' }]);
          }
          break;
        case 'updatePatientRecord':
          const patientCode = prompt("Enter patient code:");
          const updateInfo = prompt("Enter update information:");
          if (patientCode && updateInfo) {
            await updatePatientRecord(patientCode, updateInfo);
            setMessages(prevMessages => [...prevMessages, { text: `Updated record for patient with code: ${patientCode}`, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        case 'emergencyContacts':
          try {
            const contactMessages = await getEmergencyContacts(language);
            const title = language === 'english' 
              ? "Emergency Contacts:\n\n" 
              : "Nimero za telefoni zihamagarwa mu bihe by'ubutabazi:\n\n";
            const actionMessage = title + Object.entries(contactMessages)
              .map(([key, value]) => `${key.toUpperCase()}: ${value}`)
              .join('\n\n');
            setMessages(prevMessages => [...prevMessages, { text: actionMessage, sender: 'bot' }]);
          } catch (error) {
            console.error('Error getting emergency contacts:', error);
            const errorMessage = language === 'english'
              ? "Unable to retrieve emergency contact information. Please try again later."
              : "Ntabwo byashobotse kubona amakuru y'umubare wa telefone mu gihe cy'ikibazo cya ngombwa. Nyamuneka, ongera ugerageze.";
            setMessages(prevMessages => [...prevMessages, { text: errorMessage, sender: 'bot' }]);
          }
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mobile-nurse-chatbot">
      <div className="chatbot-header">
        <h1>Keza Health Bot Nurse</h1>
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle menu">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isMenuOpen && (
        <div className="menu">
          <button onClick={() => handleAction('addMedication')}><PlusCircle size={20} /> Add Medication</button>
          <button onClick={() => handleAction('addNewPatient')}><UserPlus size={20} /> Add New Patient</button>
          <button onClick={() => handleAction('getPatientList')}><Users size={20} /> View Patient List</button>
          <button onClick={() => handleAction('scheduleFollowUp')}><Calendar size={20} /> Schedule Follow-up</button>
          <button onClick={() => handleAction('updatePatientRecord')}><Edit size={20} /> Update Patient Record</button>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} /> Switch Language</button>
          <button onClick={() => handleAction('emergencyContacts')}><FileText size={20} /> Emergency Contact</button>
        </div>
      )}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="message-bubble">
              {message.text}
            </div>
          </div>
        ))}
        {loading && (
          <div className="message bot">
            <div className="message-bubble loading">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          placeholder={language === 'english' ? "Type your message..." : "Andika ubutumwa bwawe..."}
          aria-label="Message input"
        />
        <button onClick={handleSendMessage} disabled={loading} aria-label="Send message">
          <Send size={20} />
        </button>
      </div>
    </div>
  );
};

function getRandomGreeting(lang) {
  const greetings = {
    english: [
      "Hello! How can I assist you today?",
      "Welcome to Keza Health Bot Nurse. How may I help you?",
      "Greetings! What can I do for you today?",
      "Hi there! How can I be of service?",
      "Good day! What questions do you have for me?"
    ],
    kinyarwanda: [
      "Muraho! Ni gute nabafasha uyu munsi?",
      "Murakaza neza kuri Keza Health Bot Nurse. Ni gute nabafasha?",
      "Mwiriwe! Ni ibihe bibazo mwaba mufite?"
    ]
  };
  return greetings[lang][Math.floor(Math.random() * greetings[lang].length)];
}

export default NurseDashboard;
