// nurseService.js
import axios from 'axios';

const validCodes = ['1', '2', '3', '4', '5'];

const isValidCode = (code) => validCodes.includes(code);

const getErrorMessage = (language, errorType = 'invalid') => {
  if (errorType === 'invalid') {
    return language === 'english'
      ? "Invalid patient code. Please try again or contact your nurse."
      : "Umubare w'umurwayi utariwo. Nyamuneka ongera ugerageze cyangwa uvugane n'umuforomo wawe.";
  } else if (errorType === 'api') {
    return language === 'english'
      ? "Unable to retrieve patient information. Please try again later or contact your nurse."
      : "Ntabwo byashobotse kubona amakuru y'umurwayi. Nyamuneka, ongera ugerageze cyangwa uvugane n'umuforomo wawe.";
  }
};

// Mock data for testing
const mockData = {
  records: {
    '1': 'Hello Jane, \nYour recent vitals: Blood pressure: 118/76, Heart rate: 68 bpm, Pregnancy test: Positive',
    '2': 'Hello Jose, \nYour health info: Allergies: Penicillin, Last menstrual period: 2023-06-01',
    '3': 'Hello Jolene, \nYour measurements: Weight: 65kg, Height: 163cm, Gestational age: 7 weeks',
    '4': 'Hello Janiro, \nYour lab results: Hemoglobin: 12.5 g/dL, Rh factor: Positive',
    '5': 'Hello Jenny, \nYour ultrasound results: Intrauterine pregnancy confirmed, no abnormalities detected'
  },
  medications: {
    '1': {
      name: 'Jane',
      meds: [
        { name: 'Mifepristone', dosage: '200mg', instructions: 'Take orally, single dose' },
        { name: 'Misoprostol', dosage: '800mcg', instructions: 'Take buccally, 24-48 hours after mifepristone' }
      ]
    },
    '2': {
      name: 'Jose',
      meds: [
        { name: 'Misoprostol', dosage: '800mcg', instructions: 'Take buccally, 24-48 hours after mifepristone' },
        { name: 'Ibuprofen', dosage: '600mg', instructions: 'Take every 6 hours as needed for pain' }
      ]
    },
    '3': {
      name: 'Jolene',
      meds: [
        { name: 'Ibuprofen', dosage: '600mg', instructions: 'Take every 6 hours as needed for pain' },
        { name: 'Ondansetron', dosage: '4mg', instructions: 'Take every 6 hours as needed for nausea' }
      ]
    },
    '4': {
      name: 'Janiro',
      meds: [
        { name: 'Ondansetron', dosage: '4mg', instructions: 'Take every 6 hours as needed for nausea' },
        { name: 'Prenatal vitamins', dosage: 'As directed', instructions: 'Take daily' }
      ]
    },
    '5': {
      name: 'Jenny',
      meds: [
        { name: 'Prenatal vitamins', dosage: 'As directed', instructions: 'Take daily' }
      ]
    }
  },
  appointments: {
    '1': 'Jane, your upcoming appointments: \nInitial consultation and counseling on 2024-07-10',
    '2': 'Jose, please note: \nMedication administration (mifepristone) on 2024-07-12',
    '3': 'Jolene, we\'ll be in touch: \nFollow-up phone call scheduled for 2024-07-14',
    '4': 'Janiro, don\'t forget: \nIn-person follow-up appointment on 2024-07-26',
    '5': 'Jenny, just a reminder: \nPost-abortion counseling available if needed'
  },
  emergencyContacts: {
    nurse: '0787171273',
    police: '112',
    rib: '166',
    isange: '2040'
  }
};

const USE_MOCK_DATA = true; // Set this to false to use real API calls

export const getPatientRecords = async (patientCode, language) => {
  console.log(`Attempting to get patient records for code: ${patientCode}`);
  if (!isValidCode(patientCode)) {
    console.log(`Invalid patient code: ${patientCode}`);
    throw new Error(getErrorMessage(language, 'invalid'));
  }

  try {
    let records;
    if (USE_MOCK_DATA) {
      records = mockData.records[patientCode];
      console.log('Using mock data for patient records');
    } else {
      const response = await axios.get(`/api/patient-records/${patientCode}`);
      console.log('API response:', response.data);
      records = response.data;
    }
    
    return language === 'english'
      ? `Your medical records: ${records}`
      : `Dosiye yawe y'ubuvuzi: ${records}`;
  } catch (error) {
    console.error('Error getting patient records:', error);
    throw new Error(getErrorMessage(language, 'api'));
  }
};

export const getPatientMedications = async (patientCode, language) => {
  console.log(`Attempting to get patient medications for code: ${patientCode}`);
  if (!isValidCode(patientCode)) {
    console.log(`Invalid patient code: ${patientCode}`);
    throw new Error(getErrorMessage(language, 'invalid'));
  }

  try {
    let medications;
    if (USE_MOCK_DATA) {
      medications = mockData.medications[patientCode];
      console.log('Using mock data for patient medications');
    } else {
      const response = await axios.get(`/api/patient-medications/${patientCode}`);
      console.log('API response:', response.data);
      medications = response.data;
    }

    const formattedMedications = medications.meds.map(med => 
      `${med.name} ${med.dosage}: ${med.instructions}`
    ).join('\n');

    return language === 'english'
      ? `Hello ${medications.name}, your current medications are:\n${formattedMedications}`
      : `Muraho ${medications.name}, imiti yawe ya none ni:\n${formattedMedications}`;
  } catch (error) {
    console.error('Error getting patient medications:', error);
    throw new Error(getErrorMessage(language, 'api'));
  }
};

export const getPatientAppointments = async (patientCode, language) => {
  console.log(`Attempting to get patient appointments for code: ${patientCode}`);
  if (!isValidCode(patientCode)) {
    console.log(`Invalid patient code: ${patientCode}`);
    throw new Error(getErrorMessage(language, 'invalid'));
  }

  try {
    let appointments;
    if (USE_MOCK_DATA) {
      appointments = mockData.appointments[patientCode];
      console.log('Using mock data for patient appointments');
    } else {
      const response = await axios.get(`/api/patient-appointments/${patientCode}`);
      console.log('API response:', response.data);
      appointments = response.data;
    }

    return language === 'english'
      ? `Your upcoming appointments: ${appointments}`
      : `Gahunda zawe zizaza: ${appointments}`;
  } catch (error) {
    console.error('Error getting patient appointments:', error);
    throw new Error(getErrorMessage(language, 'api'));
  }
};

export const getEmergencyContacts = async (language) => {
  console.log('Attempting to get emergency contacts');
  try {
    let emergencyContacts;
    if (USE_MOCK_DATA) {
      emergencyContacts = mockData.emergencyContacts;
      console.log('Using mock data for emergency contacts');
    } else {
      const response = await axios.get('/api/emergency-contacts');
      console.log('API response:', response.data);
      emergencyContacts = response.data;
    }

    const contactMessages = {
      nurse: language === 'english'
        ? `In case of a medical emergency, please contact your assigned nurse at: ${emergencyContacts.nurse}`
        : `Mu gihe cy'ikibazo cya mbere cya ngombwa kijyanye n'ubuzima, nyamuneka hamagara umuforomo wawe kuri: ${emergencyContacts.nurse}`,
      police: language === 'english'
        ? `To report a crime or emergency, please contact the police at: ${emergencyContacts.police}`
        : `Kugira ngo mumenyeshe icyaha cyangwa ikibazo cya ngombwa, nyamuneka hamagara polisi kuri: ${emergencyContacts.police}`,
      rib: language === 'english'
        ? `To contact Rwanda Investigation Bureau (RIB), please call: ${emergencyContacts.rib}`
        : `Kugira ngo muhamagare Ikigo cy'Igihugu Gishinzwe Iperereza (RIB), nyamuneka hamagara: ${emergencyContacts.rib}`,
      isange: language === 'english'
        ? `For support and assistance from Isange Center, please reach out to: ${emergencyContacts.isange}`
        : `Kugira ngo mubone ubufasha n'inkunga kuva kwa Isange Center, nyamuneka mwiyambaze: ${emergencyContacts.isange}`
    };
    return contactMessages;
  } catch (error) {
    console.error('Error getting emergency contacts:', error);
    // Fallback to hard-coded emergency numbers if there's an error
    const fallbackContacts = {
      nurse: language === 'english'
        ? `In case of a medical emergency, please contact your assigned nurse at: 0787171717`
        : `Mu gihe cy'ikibazo cya mbere cya ngombwa kijyanye n'ubuzima, nyamuneka hamagara umuforomo wawe kuri: 0787171717`,
      police: language === 'english'
        ? `To report a crime or emergency, please contact the police at: 112`
        : `Kugira ngo mumenyeshe icyaha cyangwa ikibazo cya ngombwa, nyamuneka hamagara polisi kuri: 112`,
      rib: language === 'english'
        ? `To contact Rwanda Investigation Bureau (RIB), please call: 166`
        : `Kugira ngo muhamagare Ikigo cy'Igihugu Gishinzwe Iperereza (RIB), nyamuneka hamagara: 166`,
      isange: language === 'english'
        ? `For support and assistance from Isange Center, please reach out to: 2040`
        : `Kugira ngo mubone ubufasha n'inkunga kuva kwa Isange Center, nyamuneka mwiyambaze: 2040`
    };
    return fallbackContacts;
  }
};