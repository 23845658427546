import React, { useState, useEffect } from 'react';
import { Send, Menu, X, Globe, FileText, UserPlus, Users, Edit, Calendar, MessageSquare } from 'lucide-react';

import {
  getNurseList,
  getNursePatients,
  addNewNurse,
  scheduleFollowUp,
  viewNurseReports,
  assignPatientToNurse,
  chatWithPatient,
  getDoctorBotResponse
} from '../../services/nurseChatbotLogic';
import './DoctorChatbot.css';

const DoctorDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    setMessages([{ text: getRandomGreeting(language), sender: 'bot' }]);
  }, [language]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setLoading(true);

    try {
      const botResponse = await getDoctorBotResponse(inputMessage, language);
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setIsMenuOpen(false);
    setLoading(true);
    try {
      switch (action) {
        case 'getNurseList':
          const nurses = await getNurseList();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse List:\n${nurses.map(n => `${n.name} (ID: ${n.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'getNursePatients':
          const nurseId = prompt("Enter nurse ID:");
          if (nurseId) {
            const patients = await getNursePatients(nurseId);
            setMessages(prevMessages => [...prevMessages, { text: `Patients for Nurse ${nurseId}:\n${patients.map(p => `${p.name} (ID: ${p.id})`).join('\n')}`, sender: 'bot' }]);
          }
          break;
        case 'addNewNurse':
          const nurseName = prompt("Enter nurse's name:");
          if (nurseName) {
            const result = await addNewNurse(nurseName);
            setMessages(prevMessages => [...prevMessages, { text: `New nurse added: ${result.name}. ID: ${result.id}`, sender: 'bot' }]);
          }
          break;
        case 'scheduleFollowUp':
          const patientId = prompt("Enter patient ID:");
          const followUpDate = prompt("Enter follow-up date (YYYY-MM-DD):");
          if (patientId && followUpDate) {
            await scheduleFollowUp(patientId, followUpDate);
            setMessages(prevMessages => [...prevMessages, { text: `Scheduled follow-up for patient with ID: ${patientId} on ${followUpDate}`, sender: 'bot' }]);
          }
          break;
        case 'viewNurseReports':
          const reports = await viewNurseReports();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse Reports:\n${reports.map(r => `Nurse ${r.nurseId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'assignPatientToNurse':
          const assignNurseId = prompt("Enter nurse ID:");
          const assignPatientId = prompt("Enter patient ID:");
          if (assignNurseId && assignPatientId) {
            await assignPatientToNurse(assignNurseId, assignPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Assigned patient ${assignPatientId} to nurse ${assignNurseId}`, sender: 'bot' }]);
          }
          break;
        case 'chatWithPatient':
          const chatPatientId = prompt("Enter patient ID to chat with:");
          if (chatPatientId) {
            const chatResult = await chatWithPatient(chatPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Chat initiated with patient ${chatPatientId}. ${chatResult}`, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mobile-doctor-chatbot">
      <div className="chatbot-header">
        <h1>Keza Health Bot Doctor</h1>
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle menu">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isMenuOpen && (
        <div className="menu">
          <button onClick={() => handleAction('getNurseList')}><Users size={20} /> List of Nurses</button>
          <button onClick={() => handleAction('getNursePatients')}><Users size={20} /> Nurse's Patients</button>
          <button onClick={() => handleAction('addNewNurse')}><UserPlus size={20} /> Add New Nurse</button>
          <button onClick={() => handleAction('scheduleFollowUp')}><Calendar size={20} /> Schedule Follow-up</button>
          <button onClick={() => handleAction('viewNurseReports')}><FileText size={20} /> View Nurse Reports</button>
          <button onClick={() => handleAction('assignPatientToNurse')}><Edit size={20} /> Assign Patient to Nurse</button>
          <button onClick={() => handleAction('chatWithPatient')}><MessageSquare size={20} /> Chat with Patient</button>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} /> Switch Language</button>
        </div>
      )}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="message-bubble">
              {message.text}
            </div>
          </div>
        ))}
        {loading && (
          <div className="message bot">
            <div className="message-bubble loading">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          placeholder={language === 'english' ? "Type your message..." : "Andika ubutumwa bwawe..."}
          aria-label="Message input"
        />
        <button onClick={handleSendMessage} disabled={loading} aria-label="Send message">
          <Send size={20} />
        </button>
      </div>
    </div>
  );
};

function getRandomGreeting(lang) {
  const greetings = {
    english: [
      "Hello, Doctor! How can I assist you today?",
      "Welcome to Keza Health Bot Doctor. What would you like to do?",
      "Greetings, Doctor! How may I help you?",
      "Good day, Doctor! What can I do for you?",
      "Hello! What medical matters can I assist you with today?"
    ],
    kinyarwanda: [
      "Muraho Muganga! Ni gute nabafasha uyu munsi?",
      "Murakaza neza kuri Keza Health Bot Doctor. Ni iki mwifuza gukora?",
      "Mwiriwe Muganga! Ni gute nabafasha?",
      "Mwaramutse Muganga! Ni ibihe bibazo by'ubuvuzi nabafasha uyu munsi?"
    ]
  };
  return greetings[lang][Math.floor(Math.random() * greetings[lang].length)];
}

export default DoctorDashboard;