import React, { useState, useEffect } from 'react';
import { Send, Menu, X, Globe, FileText, UserPlus, Users, Edit, Calendar, MessageSquare } from 'lucide-react';

import {
  getNurseList,
  getPatientList,
  addNewPatient,
  addNewNurse,
  addNewDoctor,
  viewNurseReports,
  viewDoctorReports,
  assignPatientToNurse,
  assignNurseToDoctor,
  chatWithPatient,
  chatWithNurse,
  chatWithDoctor,
  getAdminBotResponse
} from '../../services/adminChatbotLogic';
import './AdminChatbot.css';

const AdminDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    setMessages([{ text: getRandomGreeting(language), sender: 'bot' }]);
  }, [language]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setLoading(true);

    try {
      const botResponse = await getAdminBotResponse(inputMessage, language);
      setMessages(prevMessages => [...prevMessages, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (action) => {
    setIsMenuOpen(false);
    setLoading(true);
    try {
      switch (action) {
        case 'getNurseList':
          const nurses = await getNurseList();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse List:\n${nurses.map(n => `${n.name} (ID: ${n.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'getPatientList':
          const patients = await getPatientList();
          setMessages(prevMessages => [...prevMessages, { text: `Patient List:\n${patients.map(p => `${p.name} (ID: ${p.id})`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'addNewPatient':
          const patientName = prompt("Enter patient's name:");
          if (patientName) {
            const result = await addNewPatient(patientName);
            setMessages(prevMessages => [...prevMessages, { text: `New patient added: ${result.name}. ID: ${result.id}`, sender: 'bot' }]);
          }
          break;
        case 'addNewNurse':
          const nurseName = prompt("Enter nurse's name:");
          if (nurseName) {
            const result = await addNewNurse(nurseName);
            setMessages(prevMessages => [...prevMessages, { text: `New nurse added: ${result.name}. ID: ${result.id}`, sender: 'bot' }]);
          }
          break;
        case 'addNewDoctor':
          const doctorName = prompt("Enter doctor's name:");
          if (doctorName) {
            const result = await addNewDoctor(doctorName);
            setMessages(prevMessages => [...prevMessages, { text: `New doctor added: ${result.name}. ID: ${result.id}`, sender: 'bot' }]);
          }
          break;
        case 'viewNurseReports':
          const nurseReports = await viewNurseReports();
          setMessages(prevMessages => [...prevMessages, { text: `Nurse Reports:\n${nurseReports.map(r => `Nurse ${r.nurseId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'viewDoctorReports':
          const doctorReports = await viewDoctorReports();
          setMessages(prevMessages => [...prevMessages, { text: `Doctor Reports:\n${doctorReports.map(r => `Doctor ${r.doctorId}: ${r.report}`).join('\n')}`, sender: 'bot' }]);
          break;
        case 'assignPatientToNurse':
          const assignNurseId = prompt("Enter nurse ID:");
          const assignPatientId = prompt("Enter patient ID:");
          if (assignNurseId && assignPatientId) {
            await assignPatientToNurse(assignNurseId, assignPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Assigned patient ${assignPatientId} to nurse ${assignNurseId}`, sender: 'bot' }]);
          }
          break;
        case 'assignNurseToDoctor':
          const doctorId = prompt("Enter doctor ID:");
          const nurseId = prompt("Enter nurse ID:");
          if (doctorId && nurseId) {
            await assignNurseToDoctor(doctorId, nurseId);
            setMessages(prevMessages => [...prevMessages, { text: `Assigned nurse ${nurseId} to doctor ${doctorId}`, sender: 'bot' }]);
          }
          break;
        case 'chatWithPatient':
          const chatPatientId = prompt("Enter patient ID to chat with:");
          if (chatPatientId) {
            const chatResult = await chatWithPatient(chatPatientId);
            setMessages(prevMessages => [...prevMessages, { text: `Chat initiated with patient ${chatPatientId}. ${chatResult}`, sender: 'bot' }]);
          }
          break;
        case 'chatWithNurse':
          const chatNurseId = prompt("Enter nurse ID to chat with:");
          if (chatNurseId) {
            const chatResult = await chatWithNurse(chatNurseId);
            setMessages(prevMessages => [...prevMessages, { text: `Chat initiated with nurse ${chatNurseId}. ${chatResult}`, sender: 'bot' }]);
          }
          break;
        case 'chatWithDoctor':
          const chatDoctorId = prompt("Enter doctor ID to chat with:");
          if (chatDoctorId) {
            const chatResult = await chatWithDoctor(chatDoctorId);
            setMessages(prevMessages => [...prevMessages, { text: `Chat initiated with doctor ${chatDoctorId}. ${chatResult}`, sender: 'bot' }]);
          }
          break;
        case 'switchLanguage':
          const newLanguage = language === 'english' ? 'kinyarwanda' : 'english';
          setLanguage(newLanguage);
          setMessages(prevMessages => [...prevMessages, { text: `Language switched to ${newLanguage === 'english' ? 'English' : 'Kinyarwanda'}`, sender: 'bot' }]);
          break;
        default:
          setMessages(prevMessages => [...prevMessages, { text: "This feature is not implemented yet.", sender: 'bot' }]);
          break;
      }
    } catch (error) {
      setMessages(prevMessages => [...prevMessages, { text: "I'm sorry, I encountered an error. Please try again.", sender: 'bot' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mobile-admin-chatbot">
      <div className="chatbot-header">
        <h1>Keza Health Bot Admin</h1>
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle menu">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isMenuOpen && (
        <div className="menu">
          <button onClick={() => handleAction('getNurseList')}><Users size={20} className="icon" /> List of Nurses</button>
          <button onClick={() => handleAction('getPatientList')}><Users size={20} className="icon" /> List of Patients</button>
          <button onClick={() => handleAction('addNewPatient')}><UserPlus size={20} className="icon" /> Add a new Patient</button>
          <button onClick={() => handleAction('addNewNurse')}><UserPlus size={20} className="icon" /> Add a new Nurse</button>
          <button onClick={() => handleAction('addNewDoctor')}><UserPlus size={20} className="icon" /> Add a new Doctor</button>
          <button onClick={() => handleAction('viewNurseReports')}><FileText size={20} className="icon" /> View Nurse Reports</button>
          <button onClick={() => handleAction('viewDoctorReports')}><FileText size={20} className="icon" /> View Doctor Reports</button>
          <button onClick={() => handleAction('assignPatientToNurse')}><Edit size={20} className="icon" /> Assign Patient to Nurse</button>
          <button onClick={() => handleAction('assignNurseToDoctor')}><Edit size={20} className="icon" /> Assign a Nurse to Doctor</button>
          <button onClick={() => handleAction('chatWithPatient')}><MessageSquare size={20} className="icon" /> Chat with a Patient</button>
          <button onClick={() => handleAction('chatWithNurse')}><MessageSquare size={20} className="icon" /> Chat with a Nurse</button>
          <button onClick={() => handleAction('chatWithDoctor')}><MessageSquare size={20} className="icon" /> Chat with a Doctor</button>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} className="icon" /> Switch Language</button>
        </div>
      )}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="message-bubble">
              {message.text}
            </div>
          </div>
        ))}
        {loading && (
          <div className="message bot">
            <div className="message-bubble loading">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          placeholder={language === 'english' ? "Type your message..." : "Andika ubutumwa bwawe..."}
          aria-label="Message input"
        />
        <button onClick={handleSendMessage} disabled={loading} aria-label="Send message">
          <Send size={20} />
        </button>
      </div>
    </div>
  );
};

function getRandomGreeting(lang) {
  const greetings = {
    english: [
      "Hello, Admin! How can I assist you today?",
      "Welcome to Keza Health Bot Admin. What would you like to do?",
      "Greetings, Admin! How may I help you?",
      "Good day, Admin! What can I do for you?",
      "Hello! What administrative tasks can I help you with today?"
    ],
    kinyarwanda: [
      "Muraho Muyobozi! Ni gute nabafasha uyu munsi?",
      "Murakaza neza kuri Keza Health Bot Admin. Ni iki mwifuza gukora?",
      "Mwiriwe Muyobozi! Ni gute nabafasha?",
      "Mwaramutse Muyobozi! Ni ibihe bikorwa by'ubuyobozi nabafasha uyu munsi?"
    ]
  };
  return greetings[lang][Math.floor(Math.random() * greetings[lang].length)];
}

export default AdminDashboard;