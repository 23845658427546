// englishContent.js

const englishContent = {
    greetings: [
      "Hello! I'm the keza health chatbot. How can I assist you with information about Rwanda's abortion policy?",
      "Welcome to keza health! I'm here to answer your questions about Rwanda's abortion policy. What would you like to know?",
      "Greetings! I'm the keza health AI assistant focused on Rwanda's abortion policy. Feel free to ask me anything about it."
    ],
    generalResponses: {
      hi: "Hello! How can I help you today?",
      hello: "Hi there! What would you like to know about Rwanda's abortion policy?",
      hey: "Hey! I'm here to assist you. What questions do you have?",
      thanks: "You're welcome! Is there anything else I can help you with?",
      bye: "Goodbye! If you have more questions in the future, don't hesitate to ask.",
      unclear: "I'm not sure I understand. Could you please rephrase your question?"
    },
    allowedGrounds: [
      "The allowed grounds for abortion in Rwanda are:",
      "1. The pregnant person is a child",
      "2. The person became pregnant as a result of rape",
      "3. The person became pregnant after being subjected to a forced marriage",
      "4. The person became pregnant as a result of incest committed with a person to the second degree of kinship",
      "5. The pregnancy puts at risk the health of the pregnant person or of the foetus"
    ],
    gestationLimit: "Except in cases where the pregnancy puts at risk the health of the pregnant person or the foetus, abortion cannot be performed if the gestation is beyond twenty-two (22) weeks.",
    eligibleFacility: "Abortion is performed in a public or private health facility licensed as a hospital or a polyclinic by the Minister in charge of health.",
    confidentiality: "The medical doctor and the health facility that received the person requesting for abortion services must ensure the respect of the right to confidentiality.",
    default: "I'm sorry, I don't have specific information about that. You can ask about allowed grounds, gestation limit, eligible facilities, or confidentiality.",
    specificResponses: {
      child: "If the pregnant person is a child (under 18), they are eligible for an abortion. This is to protect young individuals from the physical and psychological risks of early pregnancy and childbirth. The child's consent is considered even if their legal representative refuses.",
      rape: "In cases of rape, the person is eligible for an abortion. This recognizes the traumatic nature of the incident and allows the individual to make a choice about their pregnancy. The policy does not require providing evidence of the rape.",
      forcedMarriage: "If the pregnancy resulted from a forced marriage, an abortion is permitted. This acknowledges the lack of consent in the relationship and protects individuals from being forced to carry a pregnancy against their will.",
      incest: "Pregnancies resulting from incest (up to the second degree of kinship) are grounds for abortion. This is due to the ethical concerns and potential genetic risks associated with such pregnancies.",
      health: "If the pregnancy poses a risk to the health of the pregnant person or the fetus, an abortion can be performed. This includes both physical and mental health considerations. In these cases, the 22-week gestation limit does not apply.",
      consent: "A person requesting an abortion must give their written consent after receiving comprehensive explanations. For children or those with mental disabilities, their legal representative gives consent. However, if the legal representative refuses, the child's consent is considered.",
      preProcedure: "Before performing an abortion, the medical doctor must conduct comprehensive pre-abortion counseling and a thorough clinical assessment.",
      postProcedure: "After the procedure, appropriate post-abortion care should be provided, including counseling on family planning methods if desired.",
      cost: "The policy does not specify costs. Fees may vary depending on the facility. For accurate information on costs, it's best to contact a licensed healthcare facility directly.",
      legalConsequences: "The policy focuses on providing safe and legal access to abortion services under specific circumstances. It does not discuss legal consequences for seeking an abortion within these guidelines.",
      conscientious: "The policy does not explicitly address conscientious objection by healthcare providers. However, licensed facilities are required to provide the service if the grounds are met.",
      accompaniment: "The policy does not specify rules about accompaniment during the procedure. It's best to ask the healthcare facility about their specific policies.",
      waitingPeriod: "There is no mandatory waiting period specified in the policy. The focus is on providing timely care when the grounds for abortion are met.",
      parental: "For minors (under 18), the legal representative's consent is typically required. However, if they refuse, the child's own consent is considered valid.",
      medical: "Both medical (using medication) and surgical methods of abortion may be available, depending on the stage of pregnancy and the facility's capabilities.",
      mentalHealth: "Mental health is considered part of overall health. If the pregnancy poses a risk to the pregnant person's mental health, this could be grounds for an abortion.",
      fatalConditions: "If a fetal condition is detected that poses a risk to the health of the fetus, this could be grounds for an abortion, even beyond the 22-week limit.",
      emergency: "In emergency situations where the pregnant person's life is at immediate risk, immediate medical intervention is prioritized.",
      foreignNationals: "The policy does not specify different rules for foreign nationals. The same guidelines should apply to anyone seeking abortion services in Rwanda.",
      referral: "If a facility is unable to provide the necessary care, they should refer the patient to an appropriate facility that can provide the required services.",
      appeal: "The policy does not specify an appeal process if an abortion request is denied. In such cases, seeking a second opinion at another licensed facility might be an option.",
      records: "Medical records related to abortion services are kept confidential, like all medical records. Only authorized medical personnel should have access to these records."
    },
    nurseContact: "To talk to a nurse, please call our helpline at +250787171273. A healthcare professional will assist you shortly.",
    commonQuestions: {
      "What are the legal grounds for abortion?": "allowedGrounds",
      "How late can I get an abortion?": "gestationLimit",
      "Where can I get an abortion?": "eligibleFacility",
      "Is my abortion confidential?": "confidentiality",
      "Can a minor get an abortion?": "child",
      "What if I was raped?": "rape",
      "Does forced marriage qualify for abortion?": "forcedMarriage",
      "Is incest a reason for abortion?": "incest",
      "What if the pregnancy is risky?": "health",
      "Do I need to give consent?": "consent",
      "What happens before the abortion?": "preProcedure",
      "What about after the abortion?": "postProcedure",
      "How much does it cost?": "cost",
      "Are there legal consequences?": "legalConsequences",
      "Can doctors refuse to perform abortions?": "conscientious",
      "Can someone come with me?": "accompaniment",
      "Is there a waiting period?": "waitingPeriod",
      "Do my parents need to know?": "parental",
      "What methods of abortion are available?": "medical",
      "Does mental health count?": "mentalHealth",
      "What if the fetus has a fatal condition?": "fatalConditions",
      "What about emergencies?": "emergency",
      "I'm not Rwandan, can I still get an abortion?": "foreignNationals",
      "What if my local clinic can't help?": "referral",
      "Can I appeal if I'm denied an abortion?": "appeal",
      "How are abortion records kept?": "records"
    }
  };
  
  export default englishContent;