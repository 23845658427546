import React, { useState, useEffect, useRef } from 'react';
import { Send, Calendar, FileText, Globe } from 'lucide-react';
import axios from 'axios';
import englishContent from '../../../services/englishContent.js';
import kinyarwandaContent from '../../../services/kinyarwandaContent.js';
import { storeQuestion } from '../../../services/questionStore.js';
import { getPatientRecords, getPatientMedications, getPatientAppointments, getEmergencyContacts } from '../../../services/nurseService.js';
import './PatientDashboard.css';

// Policy data containing content for both languages
const policyData = {
  english: englishContent,
  kinyarwanda: kinyarwandaContent
};

// Azure OpenAI configuration
const azureOpenAIKey = 'fb299db1bc7a4780a5afb4653f3de7d3';
const azureOpenAIEndpoint = 'https://kezabot.openai.azure.com/';
const deploymentName = 'kezabot';

const KezaHealthChatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [patientCode, setPatientCode] = useState('');
  const [language, setLanguage] = useState('english');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInputCode, setModalInputCode] = useState('');
  const [currentAction, setCurrentAction] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const greetingMessage = { text: getRandomGreeting(language), sender: 'bot' };
    setMessages([greetingMessage]);
  }, [language]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '') return;

    const userMessage = { text: inputMessage, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);

    if (inputMessage.toLowerCase().startsWith('code:')) {
      await handlePatientCode(inputMessage);
    } else {
      const botResponse = await getBotResponse(inputMessage, language, patientCode);
      const botMessage = { text: botResponse, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }

    setInputMessage('');
    try {
      await storeQuestion(inputMessage, language);
    } catch (error) {
      console.error('Error storing question:', error);
    }
  };

  const handlePatientCode = async (input) => {
    const code = input.split(':')[1].trim();
    setPatientCode(code);
    const welcomeMessage = await getBotResponse(`Welcome! Your care is now personalized. How can I assist you?`, language, code);
    setMessages(prevMessages => [...prevMessages, { text: welcomeMessage, sender: 'bot' }]);
  };

  const handleAction = async (action) => {
    setCurrentAction(action);
    if (action === 'viewRecords' || action === 'viewMedications' || action === 'viewAppointments') {
      setIsModalOpen(true);
    } else {
      let actionMessage = '';
      switch (action) {
        case 'switchLanguage':
          setLanguage(prevLanguage => prevLanguage === 'english' ? 'kinyarwanda' : 'english');
          actionMessage = `Language switched to ${language === 'english' ? 'Kinyarwanda' : 'English'}`;
          break;
        case 'emergencyContacts':
          try {
            const contactMessages = await getEmergencyContacts(language);
            const title = language === 'english' 
              ? "Emergency Contacts:\n\n" 
              : "Nimero za telefoni zihamagarwa mu bihe by'ubutabazi:\n\n";
            actionMessage = title + Object.entries(contactMessages)
              .map(([key, value]) => `${key.toUpperCase()}: ${value}`)
              .join('\n\n');
          } catch (error) {
            console.error('Error getting emergency contacts:', error);
            actionMessage = language === 'english'
              ? "Unable to retrieve emergency contact information. Please try again later."
              : "Ntabwo byashobotse kubona amakuru y'umubare wa telefone mu gihe cy'ikibazo cya ngombwa. Nyamuneka, ongera ugerageze.";
          }
          break;
        default:
          actionMessage = 'Action not recognized';
      }
      const botMessage = { text: actionMessage, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }
  };

  const handleModalSubmit = async () => {
    setIsModalOpen(false);
    let actionMessage = '';
    let isValidCode = false;

    try {
      switch (currentAction) {
        case 'viewRecords':
          actionMessage = await getPatientRecords(modalInputCode, language);
          break;
        case 'viewMedications':
          actionMessage = await getPatientMedications(modalInputCode, language);
          break;
        case 'viewAppointments':
          actionMessage = await getPatientAppointments(modalInputCode, language);
          break;
        default:
          throw new Error('Invalid action');
      }
      isValidCode = true;
    } catch (error) {
      console.error('Error in handleModalSubmit:', error);
      actionMessage = error.message || (language === 'english'
        ? "An error occurred. Please try again or contact your nurse."
        : "Hari ikibazo cyabaye. Nyamuneka ongera ugerageze cyangwa uvugane n'umuforomo wawe.");
    }

    if (isValidCode) {
      const validationMessage = language === 'english'
        ? "Your care is now personalized. Here is the information regarding your request:"
        : "Ubuvuzi bwawe bwatunganyijwe. Aya ni amakuru yerekeye icyo wasabye:";
      
      const botValidationMessage = { text: validationMessage, sender: 'bot' };
      const botActionMessage = { text: actionMessage, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botValidationMessage, botActionMessage]);
      setPatientCode(modalInputCode);
    } else {
      const botMessage = { text: actionMessage, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    }

    setModalInputCode('');
  };

  return (
    <div className="keza-health-chatbot">
      <header className="chatbot-header">
        <h1>KezaHealth Patient Dashboard</h1>
      </header>
      <div className="dashboard-content">
        <aside className="sidebar">
          <h2>Patient Information</h2>
          <p>Enter your patient code for personalized information.</p>
          <button onClick={() => handleAction('switchLanguage')}><Globe size={20} /> Switch Language</button>
          <button onClick={() => handleAction('emergencyContacts')}><FileText size={20} /> Emergency Contact</button>
          <button onClick={() => handleAction('viewRecords')}><FileText size={20} /> View Records</button>
          <button onClick={() => handleAction('viewMedications')}><FileText size={20} /> View Medications</button>
          <button onClick={() => handleAction('viewAppointments')}><Calendar size={20} /> View Appointments</button>
        </aside>
        <main className="chat-area">
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.sender}`}>
                {message.text}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={language === 'english' ? "Type your message or 'code:' followed by your patient code..." : "Andika ubutumwa bwawe cyangwa 'code:' hanyuma ukurikizeho kode y'umurwayi..."}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <button onClick={handleSendMessage}><Send size={20} /></button>
          </div>
        </main>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>{language === 'english' ? "Enter Patient Code" : "Injiza Kode y'Umurwayi"}</h3>
            <input
              type="text"
              value={modalInputCode}
              onChange={(e) => setModalInputCode(e.target.value)}
              placeholder={language === 'english' ? "Patient Code" : "Kode y'Umurwayi"}
            />
            <button onClick={handleModalSubmit}>{language === 'english' ? "Submit" : "Ohereza"}</button>
          </div>
        </div>
      )}
    </div>
  );
};

async function getBotResponse(message, lang, patientCode) {
  const lowercaseMessage = message.toLowerCase();
  const data = policyData[lang];

  // Check for greetings
  const greetings = ['hi', 'hello', 'hey', 'greetings', 'good morning', 'good afternoon', 'good evening', 'yo', 'bite', 'nurse'];
  if (greetings.some(greeting => lowercaseMessage.includes(greeting))) {
    return getRandomGreeting(lang);
  }

  // Personalized responses if patientCode is provided
  if (patientCode) {
    if (lowercaseMessage.includes('medication') || lowercaseMessage.includes('medicine')) {
      return await getPersonalizedMedicationInfo(patientCode, lang);
    }
    if (lowercaseMessage.includes('appointment') || lowercaseMessage.includes('visit')) {
      return await getPersonalizedAppointmentInfo(patientCode, lang);
    }
    if (lowercaseMessage.includes('recovery') || lowercaseMessage.includes('feeling')) {
      return await getPersonalizedRecoveryInfo(patientCode, lang);
    }
  }

  // Check for predefined questions and answers
  for (const [question, responseKey] of Object.entries(data.commonQuestions)) {
    if (lowercaseMessage.includes(question.toLowerCase())) {
      return data[responseKey];
    }
  }

  // Check for specific topics
  if (lowercaseMessage.includes('grounds') || lowercaseMessage.includes('reasons') || lowercaseMessage.includes('impamvu')) {
    return data.allowedGrounds.join('\n');
  } else if (lowercaseMessage.includes('weeks') || lowercaseMessage.includes('gestation') || lowercaseMessage.includes('ibyumweru')) {
    return data.gestationLimit;
  } else if (lowercaseMessage.includes('facility') || lowercaseMessage.includes('hospital') || lowercaseMessage.includes('ikigo')) {
    return data.eligibleFacility;
  } else if (lowercaseMessage.includes('confidential') || lowercaseMessage.includes('ibanga')) {
    return data.confidentiality;
  }

  // Check for specific responses
  for (const [key, response] of Object.entries(data.specificResponses)) {
    if (lowercaseMessage.includes(key.toLowerCase())) {
      return response;
    }
  }

  // If no predefined answer is found, use Azure OpenAI
  try {
    const openAIResponse = await getOpenAIResponse(message, lang);
    return openAIResponse || data.default;
  } catch (error) {
    console.error('Error getting OpenAI response:', error);
    return data.default;
  }
}

async function getOpenAIResponse(message, lang) {
  const prompt = `You are a chatbot assistant for Rwanda's abortion policy. Provide a brief, factual response based only on the official policy of Rwanda. Ensure the response is concise and relevant. Respond in ${lang} to the following message:\n\n${message}\n\nResponse:`;

  try {
    const response = await axios.post(
      `${azureOpenAIEndpoint}/openai/deployments/${deploymentName}/chat/completions?api-version=2023-05-15`,
      {
        messages: [
          { role: "system", content: prompt },
          { role: "user", content: message }
        ],
        max_tokens: 150,
        n: 1,
        stop: null,
        temperature: 0.3,
      },
      {
        headers: {
          'api-key': azureOpenAIKey,
          'Content-Type': 'application/json',
        },
      }
    );

    const openAIResponse = response.data.choices[0].message.content.trim();
    
    if (isResponseValid(openAIResponse)) {
      return openAIResponse;
    } else {
      return policyData[lang].default;
    }
  } catch (error) {
    console.error('Azure OpenAI API error:', error);
    throw error;
  }
}

function isResponseValid(response) {
  const keyPoints = [
    'child', 'rape', 'forced marriage', 'incest', 'health risk',
    'gestation', 'week', 'facility', 'hospital', 'confidential',
    'legal', 'policy', 'law', 'procedure', 'consent', 'nurse'
  ];
  return keyPoints.some(point => response.toLowerCase().includes(point));
}

function getRandomGreeting(lang) {
  const greetings = policyData[lang].greetings;
  return greetings[Math.floor(Math.random() * greetings.length)];
}

async function getPersonalizedMedicationInfo(patientCode, lang) {
  const response = await getPatientMedications(patientCode, lang);
  return response;
}

async function getPersonalizedAppointmentInfo(patientCode, lang) {
  const response = await getPatientAppointments(patientCode, lang);
  return response;
}

async function getPersonalizedRecoveryInfo(patientCode, lang) {
  const response = await getPatientRecoveryInfo(patientCode, lang);
  return response;
}

async function getPatientRecoveryInfo(patientCode, lang) {
  // Implement the logic to fetch recovery info using patient code
  // This is a placeholder implementation. Replace it with the actual implementation.
  return `Recovery information for patient code ${patientCode} in ${lang}`;
}

export default KezaHealthChatbot;